import React from 'react';
import '../../styles/pages/tech-videos.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';
import Header from '../header';
import Sidebar from '../sidebar';

const TechVideos = () => {
  return (
    <Layout>
      <Container>
        <Header title="Technique Videos" />
        <Row>
          <Col xs={{ order: 2, span: 12 }} md={{ order: 1, span: 8 }} lg={{ order: 1, span: 9 }}>
            <p>We are still working on it!</p>
            {/* <ul className="Video__lists">
              <li className="Video__item">
                <div style={{ width: '100%', height: '200px', background: 'lightgrey' }}>Videos</div>
              </li>
              <li className="Video__item">
                <div style={{ width: '100%', height: '200px', background: 'lightgrey' }}>Videos</div>
              </li>
              <li className="Video__item">
                <div style={{ width: '100%', height: '200px', background: 'lightgrey' }}>Videos</div>
              </li>
            </ul> */}
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 2, span: 4 }}
            lg={{ order: 2, span: 3 }}
            style={{ marginTop: '15px' }}
          >
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TechVideos;
